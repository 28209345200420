import styles from './style.module.scss';
import RiskScore from 'components/risk-score/RiskScore';
import { dateObjectToDateString } from 'utils/date-formatter';
import CopyToClipboardTooltip from 'components/copy-to-clipboard-tooltip/CopyToClipboardTooltip';
import { useEffect, useMemo, useState, useContext } from 'react';
import { ellipsis, getDescriptionInfoFromAccount, unique, valueExists } from '../../utils/common';
import { ReactComponent as InfoIcon } from '../../assets/images/svg/info-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/svg/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/svg/minus-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/svg/warning-icon.svg';
import { clsx } from 'clsx';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import {
  getTxTypeDescription,
  determineTxType,
  movementSortFn,
  calculateDelta,
  determineInstitutionInfo
} from '../../utils/consolidated-transactions/consolidated-transactions';
import ConsolidatedTransactionsTableRow from './ConsolidatedTransactionsTableRow';
import { UserContext } from '../../context/user/UserContext';

const ConsolidatedTransactionsTableRowConcise = ({ data, accountInfo, ownMovementsLabel, otherRowIsExpanded, onExpand, currency = 'USD', riskLabelsOnly = false }) => {
  const { getConfig, providersInfo } = useContext(UserContext);
  const isAmlDemo = getConfig('is_aml_demo');

  const filteredInputs = useMemo(() => {
    const feeInfo = data?.fee ?? [];
    const feeCurrency = feeInfo.length > 0 ? feeInfo[0]?.currency : '';
    const feeValue = feeInfo.length > 0 ? feeInfo[0]?.value : -1;
    const feeInputCandidatesWithIndexes = data.inputs
      .map((input, index) => [input, index])
      .filter(([input, _]) => input?.currency === feeCurrency && input?.value === feeValue);
    const forbiddenIndex = feeInputCandidatesWithIndexes.length > 0 ? feeInputCandidatesWithIndexes[0][1] : -1;
    const inputsCopy = [...data.inputs];
    if (forbiddenIndex !== -1) {
      inputsCopy.splice(forbiddenIndex, 1);
    }
    return inputsCopy.toSorted(movementSortFn);
  }, [data]);
  const filteredOutputs = useMemo(() => {
    const filtered = data.outputs.filter(output => !output.fee && output.value !== 0);
    return [...filtered].toSorted(movementSortFn);
  }, [data]);
  const filteredData = useMemo(() => {
    return {
      ...data,
      inputs: filteredInputs,
      outputs: filteredOutputs
    };
  }, [data]);
  const feeOnlyTx = useMemo(() => data.outputs.every(output => output.fee), [data]);
  const parsedCategories = useMemo(() => {
    const categoriesWithDuplicates = [...data.inputs, ...data.outputs]
      .flatMap(movement => [movement.category, ...(movement?.categories ?? [])])
      .filter(valueExists);
    return unique(categoriesWithDuplicates);
  }, [filteredData]);
  const [expanded, setExpanded] = useState(false);
  const txType = useMemo(() => determineTxType(filteredData, true), [filteredData]);
  const { tooltipDescription, shortDescription, copyValue } = getDescriptionInfoFromAccount(accountInfo);
  const deltaInfo = useMemo(() => calculateDelta(filteredData), [filteredData]);
  const datetimeInfo = useMemo(() => {
    const dateString = dateObjectToDateString(filteredData.created_at, true);
    const [date, time] = dateString.split(' ');
    return { date, time };
  }, [filteredData.created_at]);
  const institutionInfo = useMemo(() => determineInstitutionInfo(filteredData, accountInfo, providersInfo), [filteredData, accountInfo]);

  useEffect(() => {
    if (otherRowIsExpanded) {
      setExpanded(false); // So that only 1 row is expanded at a time
    }
  }, [otherRowIsExpanded]);

  const toggleExpand = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
      onExpand(); // To notify other rows to collapse
    }
  };

  return <div className={clsx((isAmlDemo && txType.isWithdrawal) && styles.withdrawalRow, (isAmlDemo && txType.isDeposit) && styles.depositRow)}>
    <div className={clsx(
      styles.tableRowConcise,
      styles.rowConcise,
    )}>
      <div className={styles.centered}>
        <div
          className={clsx(styles.icon, styles.clickable)}
          onClick={toggleExpand}
        >
          {expanded ? <MinusIcon/> : <PlusIcon/>}
        </div>
      </div>
      <div className={styles.centered}>
        <div>{datetimeInfo.date}</div>
        <div>{datetimeInfo.time}</div>
      </div>
      <div className={styles.centered}>
        <div className={clsx(styles.address, styles.accountName)}>
          <CopyToClipboardWithIcon copyValue={copyValue} tooltipDisplayedData={tooltipDescription}>
            {shortDescription}
          </CopyToClipboardWithIcon>
        </div>
      </div>
      <div className={styles.centered}>
        <div className={clsx(styles.txTypeContainer)}>
          <CopyToClipboardTooltip tooltipData={getTxTypeDescription(txType.type)} openOnHover={true}>
            {txType.type}
          </CopyToClipboardTooltip>
          {feeOnlyTx && <CopyToClipboardTooltip tooltipData='Internal operation' openOnHover={true}>
            <div className={styles.warningIcon}>
              <WarningIcon className={styles.warningIconSmall} />
            </div>
          </CopyToClipboardTooltip>}
        </div>
      </div>
      <div className={styles.centered}>
        <div className={styles.transfer}>
          {valueExists(deltaInfo.prefixTransferSymbol) && <span>{deltaInfo.prefixTransferSymbol}</span>}
          {valueExists(deltaInfo.fromCurrencies) &&
            <CopyToClipboardTooltip tooltipData={deltaInfo.fromCurrencies} openOnHover>
              <span className={styles.fromCurrencies}>{ellipsis(deltaInfo.fromCurrencies, deltaInfo.maxLength)}</span>
            </CopyToClipboardTooltip>
          }
          {valueExists(deltaInfo.infixTransferSymbol) && <span>{deltaInfo.infixTransferSymbol}</span>}
          {valueExists(deltaInfo.toCurrencies) &&
            <CopyToClipboardTooltip tooltipData={deltaInfo.toCurrencies} openOnHover>
              <span className={styles.toCurrencies}>{ellipsis(deltaInfo.toCurrencies, deltaInfo.maxLength)}</span>
            </CopyToClipboardTooltip>
          }
        </div>
      </div>
      <div className={styles.centered}>
        {deltaInfo.delta.map((currencyInfo, idx) =>
          <div className={styles.currencyDelta} key={`delta-info-${String(filteredData.transaction_id)}-${idx}`}>
            <div className={styles.currencyDeltaValue}>
              <div
                className={currencyInfo.value > 0 ? styles.deltaValuePositive : styles.deltaValueNegative}
              >
                <CopyToClipboardTooltip tooltipData={data?.hideValue ? 'Unknown amount' : currencyInfo.value} openOnHover>
                  {currencyInfo.value > 0 && '+'}
                  {data?.hideValue ? '?' : Number(currencyInfo.value.toFixed(4))}
                </CopyToClipboardTooltip>
              </div>
              <div>
                <CopyToClipboardTooltip tooltipData={currencyInfo.currencySymbol} openOnHover>
                  {ellipsis(currencyInfo.currencySymbol, 8)}
                </CopyToClipboardTooltip>
              </div>
            </div>
            {/* Also show fiat value for one-sided cases (+ or -, not ->) */}
            {!data?.hideValue && deltaInfo.delta.length === 1 && <div className={styles.currencyDeltaValue}>
              <div>
                {numberToPrice(currencyInfo.convertedValue, currency, 2, true)}
              </div>
            </div>}
          </div>
        )}
      </div>
      <div className={styles.centered}>
        <div className={styles.tableRisk}>
          <RiskScore riskScore={filteredData.risk ?? 0}/>
          {!riskLabelsOnly && <span>{filteredData.risk ?? 0}/100</span>}
        </div>
      </div>
      <div className={styles.centered}>
        <CopyToClipboardTooltip
          tooltipData={
            <div>
              {[determineTxType(filteredData, false).type, ...parsedCategories, institutionInfo].filter(entry => valueExists(entry)).map((category, index) => {
                return <div className={styles.tooltipCategoryList} key={`${filteredData.transaction_id}:${category}`}>
                  <div className={index > 0 && styles.tooltipCategoryValue}>{category}{index > 0 && index < parsedCategories.length && ','}</div>
                  {(index === 0 || index === parsedCategories.length) &&
                    <div className={clsx(styles.halfWidth, styles.rowDivider, styles.rowDividerShort)}></div>}
                </div>;
              })}
            </div>
          }
          wrapperClassName={styles.wideCentered}
          openOnHover
        >
          <div className={styles.icon}>
            <InfoIcon className={styles.icon}/>
          </div>
        </CopyToClipboardTooltip>
      </div>
    </div>
    {expanded && <div className={styles.expandedSubrowContainer}>
      <div className={styles.verticalLine}></div>
      <div className={styles.expandedSubrowContentContainer}>
        <div className={clsx(styles.consolidatedTransactions, styles.expandedSubrow)}>
          <div className={clsx(styles.tableHeader, styles.tableHeaderReduced)}>
            <span>Tx hash</span>
            <span>Full Type</span>
            <span>From</span>
            <span>To</span>
            <span>Tx Category</span>
          </div>
          <div className={styles.tableBody}>
            <ConsolidatedTransactionsTableRow
              data={filteredData}
              ownMovementsLabel={ownMovementsLabel}
              accountInfo={accountInfo}
              categoriesOverride={parsedCategories}
              reduced
            />
          </div>
        </div>
      </div>
    </div>}
  </div>;
};

export default ConsolidatedTransactionsTableRowConcise;
